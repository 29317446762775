import './index.css';
import PrivateRoute from '../../routes/PrivateRoute';
import { ConnectedRouter } from 'connected-react-router';
import { Route, Switch } from 'react-router-dom';
import { history } from 'utils';
import { lazy } from 'react';
import { retry } from 'helpers/retryReactLazyFails';
import ListVideoPin from 'containers/ListVideoGim';

const Signup = lazy(() => retry(() => import('containers/Signup')));
const Signin = lazy(() => retry(() => import('containers/Signin')));
const ForgotPasswordPage = lazy(() => retry(() => import('containers/ForgotPassword')));
const ResetPasswordPage = lazy(() => retry(() => import('containers/ResetPassword')));
const ActiveAccount = lazy(() => retry(() => import('containers/ActiveAccount')));

// const DashboardPage = lazy(() => retry(() => import('containers/Dashboard')));
const ProfilePage = lazy(() => retry(() => import('containers/Profile')));
const Livechat = lazy(() => retry(() => import('containers/Livechat')))
const ProjectPage = lazy(() => retry(() => import('containers/Project')));
const ProjectDetailPage = lazy(() => retry(() => import('containers/ProjectDetail')));
const Error = lazy(() => retry(() => import('containers/Error500')));
const NotFoundPage = lazy(() => retry(() => import('containers/NotFound')));
const AnnotationTool = lazy(() => retry(() => import('containers/AnnotationTool')));
const YoutubeComment = lazy(() => retry(() => import('containers/YoutubeCommentContainer')));
const InstagramMediaContainer = lazy(() => retry(() => import('containers/InstagramMediaContainer')));
const InstagramMediaCommentContainer = lazy(() => retry(() => import('containers/InstagramMediaCommentContainer')));
const AuthCallback = lazy(() => retry(() => import('containers/AuthCallback')));
const WebhookCallback = lazy(() => retry(() => import('containers/WebhookCallback')));
const TikTokMediaComment = lazy(() => retry(() => import('containers/TiktokMediaCommentContainer')));
const NewestCommentOfSystem = lazy(() => retry(() => import('containers/NewestCommentOfSystem')));
const FacebookPosts = lazy(() => retry(() => import('containers/FacebookPosts')));
const FBPostsComments = lazy(() => retry(() => import('containers/FBPostsComments')));
const LoginZalo = lazy(() => retry(() => import('containers/LoginZalo')));
const ProfileApp = lazy(() => retry(() => import('containers/ProfileApp')));

const App = () => {
  return (
    <div className="app">
      <ConnectedRouter history={history}>
        <Switch>
          {/* <PrivateRoute exact path="/" component={DashboardPage} /> */}
          <PrivateRoute exact path="/" component={ProjectPage} />
          <PrivateRoute exact path="/project/:projectId" component={ProjectDetailPage} />
          <PrivateRoute path="/profile" component={ProfilePage} />
          <PrivateRoute path="/project/:projectId/livechat" component={Livechat} />
          <PrivateRoute exact path="/annotation-tool/:channelId" component={AnnotationTool} />
          <PrivateRoute path="/youtube/comment/:videoId" component={YoutubeComment} />
          <PrivateRoute exact path="/instagram/:accountId" component={InstagramMediaContainer} />
          <PrivateRoute path="/instagram/comment/:mediaId" component={InstagramMediaCommentContainer} />
          <PrivateRoute path="/project/:projectId/tiktok/:unique_id/comment/:mediaId" component={TikTokMediaComment} />
          <PrivateRoute path="/project/:projectId/tiktok/reply_newest_comment_of_system" component={NewestCommentOfSystem} />
          <PrivateRoute path="/project/:projectId/tiktok/pin_video" component={ListVideoPin} />
          <PrivateRoute path="/facebook-posts/:channelId" component={FacebookPosts} />
          <PrivateRoute path="/fb-posts/:channelId/posts-comments/:postId" component={FBPostsComments} />
					<PrivateRoute exact path="/profileApp" component={ProfileApp} />
          <Route exact path="/signin" component={Signin} />
          <Route exact path="/signup" component={Signup} />
          <Route exact path="/oauth-callback/:id" component={AuthCallback} />
          <Route exact path="/callback" component={WebhookCallback} />
          <Route exact path="/forget-password" component={ForgotPasswordPage} />
          <Route exact path="/active/:token_active" component={ActiveAccount} />
          <Route exact path="/reset_password/:token_reset" component={ResetPasswordPage} />
					<Route exact path="/login/zalo" component={LoginZalo} />
          <Route exact path="/error" component={Error} />
          <Route path="*" component={NotFoundPage} />
        </Switch>
      </ConnectedRouter>
    </div>
  );
};

export default App;
