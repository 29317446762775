import { createSlice } from "@reduxjs/toolkit"

export interface LivechatState {
  isLoading: boolean;
  dataConversation: any;
}

const initialState: LivechatState = {
  isLoading: false,
  dataConversation: null,
}

const vaLivechatSlice = createSlice({
  name: 'vaLivechat',
  initialState,
  reducers: {
    requestDataConversation(state, action: any) {
      state.isLoading = true;
    },
    requestDataConversationFinish(state, action: any) {
      state.isLoading = false;
      state.dataConversation = action.payload
    }
  }
})

// Actions
export const vaLivechatActions = vaLivechatSlice.actions;

// Selectors
export const selectIsLoading = (state: any) => state.vaLivechat.isLoading;
export const selectDataConversation = (state: any) => state.vaLivechat.dataConversation;

// Reducer
const vaLivechatReducer = vaLivechatSlice.reducer;
export default vaLivechatReducer;
