import React, { useEffect, useState } from 'react';
import { Button, Popconfirm, Space, Table, Modal, Form, Input  } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import { toastError, toastSuccess } from 'helpers/toast';
import './index.css';
import tiktokApi from 'api/tiktokApi';
import { DeleteOutlined } from '@ant-design/icons';

interface DataType {
	_id: string;
	id: string;
  video_id: string;
	title: string;
	action: string;
}

const ListVideoPin: React.FC = () => {
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [listVideos, setListVideos] = useState<any>()
	const [itemChannel, setItemChannel] = useState<any>()
	const [form] = Form.useForm();

	const onFinish = async (value: any) => {
		try {
      const res = await tiktokApi.pinVideo({
				id: value.id
			})
			if(res.data.success) {
        getDataListVideoPin()
        form.resetFields();
				setIsModalOpen(false);
				toastSuccess('Ghim video thành công',
				'',
				2000)
			} else {
				toastError(res?.data?.message || 'Ghim video thất bại',
				'',
				2000)
			}
		} catch (error) {
      console.log('error', error)

		}
	}


	const handleCancel = () => {
		form.resetFields();
		setIsModalOpen(false);
	};

	const getDataListVideoPin = async () => {
		try {
			const res = await tiktokApi.getVideoPined()
			const outDataVideoComment: any = [];
			const datas = res.data.data.markVideos
			datas?.forEach((i: any, index: number) => {
				outDataVideoComment.push({
					id: index + 1,
					_id: i._id,
					video_id: i.video,
          title: i.videoDetail.title,
				});
			}
			)

			setListVideos(outDataVideoComment)
			form.setFieldsValue({
				_id: '',
				video_id: '',
        title: ''
			})
		} catch (error) {
			console.log('error', error)
		}
	}

  const handleDelete = async (id: string) => {
    try {
			const res = await tiktokApi.unVideoPined(id)
			if(res.data.success) {
			  toastSuccess('Bỏ ghim thành công',
			  '',
			  2000)
			  getDataListVideoPin()
			} else {
				toastError('Bỏ ghim thất bại',
				'',
				2000)
			}
		} catch (error) {
			console.log('error', error)
		}
  }

	useEffect(() => {
		form.setFieldsValue({
			_id: itemChannel?._id || "",
			video_id: itemChannel?.video_id || "",
		})
	},[itemChannel, isModalOpen])

	useEffect(() => {
		getDataListVideoPin()
	}, [])

	const columns: ColumnsType<DataType> = [
		{
			title: 'STT',
			dataIndex: 'id',
			width: 5,

		},
		{
			title: 'ID',
			dataIndex: 'video_id',
			ellipsis: true,
			width: 25,

		},
		{
			title: 'title',
			dataIndex: 'title',
			ellipsis: true,
			width: 60,
		},
		{
			title: 'Action',
			dataIndex: '',
			width: 10,
			render: (_, item) => (
				<Space size="middle" title='Bỏ ghim video'>
					<Popconfirm title="Bạn chắc có bỏ ghim video??" onConfirm={() => handleDelete(item.video_id)}>
						<DeleteOutlined style={{ cursor: "pointer", color: "volcano" }} />
					</Popconfirm>
				</Space>
			),
		},
	];
	return (
		<div>
			<div className="" style={{ display: 'flex', justifyContent: 'space-between', padding: '0 10px'}}>
			  <h1 className="" style={{ fontSize: '24px' }}>Danh sách video ghim</h1>
			  <Button type="primary" onClick={() => {
				  setItemChannel({})
			  	setIsModalOpen(true)
		  	}} style={{ marginBottom: 16, padding: '0 10px' }}>
				Thêm video
			  </Button>
			</div>
			<Table columns={columns} dataSource={listVideos} pagination={false} className="tb"/>
			<Modal
			  title="Thêm video"
				visible={isModalOpen}
				onCancel={handleCancel}
				footer={null}>
				<Form
						form={form}
				    layout="vertical"
            autoComplete="off"
						onFinish={onFinish}
						>
					<Form.Item
            label="ID"
            name="id"
            rules={[{ required: true, message: 'Vui lòng nhập ID!' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item>
            <Button style={{ float: 'right' }} type="primary" htmlType="submit">
					   Lưu
            </Button>
          </Form.Item>
        </Form>
			</Modal>


		</div>
	)
}

export default ListVideoPin;
