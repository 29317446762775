import channelApi from "api/channelApi";
import { call, put, takeEvery } from '@redux-saga/core/effects';
import { livechatActions } from "redux/slices/livechatSlice";
import { push } from "connected-react-router";

export interface ProcessResponseType {
  code: any;
  data: any;
  message: string,
  success: Boolean,
  status: any
}

function* getDataConversation(action: any) {
  const payload = action.payload;
  try {
    const response: ProcessResponseType = yield call(channelApi.getConversationDetail, payload)
    const { success, data } = response?.data;
    if (success) yield put(livechatActions.requestDataConversationFinish(data));

  } catch (error) {
    yield put(push('/error'))
  }
}

const livechatSaga = [
  takeEvery(livechatActions.requestDataConversation.type, getDataConversation)
]

export default livechatSaga
