import channelApi from "api/channelApi";
import { call, put, takeEvery } from '@redux-saga/core/effects';
import { push } from "connected-react-router";
import { vaLivechatActions } from "redux/slices/vaLivechatSlice";

export interface ProcessResponseType {
  code: any;
  data: any;
  message: string,
  success: Boolean,
  status: any
}

function* getDataConversation(action: any) {
  const payload = action.payload;
  try {
    const response: ProcessResponseType = yield call(channelApi.getConversationDetail, payload)
    const { success, data } = response?.data;
    if (success) yield put(vaLivechatActions.requestDataConversationFinish(data));

  } catch (error) {
    yield put(push('/error'))
  }
}

const vaLivechatSaga = [
  takeEvery(vaLivechatActions.requestDataConversation.type, getDataConversation)
]

export default vaLivechatSaga
