import axiosClient from './axiosClient';
import { SignupParams, CommonResponse } from '../models';

const authApi = {
  signUp(params: SignupParams): Promise<CommonResponse> {
    const url = 'auth/register';
    return axiosClient.post(url, params);
  },

  login(params: any): Promise<CommonResponse> {
    const url = 'auth/login';
    return axiosClient.post(url, params);
  },

  activeEmail(params: any): Promise<CommonResponse> {
    const url = 'auth/active';
    return axiosClient.post(url, params);
  },

  changePassword(params: any): Promise<CommonResponse> {
    const url = 'auth/update_password';
    return axiosClient.put(url, params);
  },

  forgotPassword(params: any): Promise<CommonResponse> {
    const url = 'auth/forgot_password';
    return axiosClient.post(url, params);
  },

  resetPassword(params: any): Promise<CommonResponse> {
    const url = 'auth/reset_password';
    return axiosClient.put(url, params);
  }
}

export default authApi;


