import { createSlice } from "@reduxjs/toolkit"

export interface LivechatState {
  isLoading: boolean;
  dataConversation: any;
}

const initialState: LivechatState = {
  isLoading: false,
  dataConversation: null,
}

const livechatSlice = createSlice({
  name: 'livechat',
  initialState,
  reducers: {
    requestDataConversation(state, action: any) {
      state.isLoading = true;
    },
    requestDataConversationFinish(state, action: any) {
      state.isLoading = false;
      state.dataConversation = action.payload
    }
  }
})

// Actions
export const livechatActions = livechatSlice.actions;

// Selectors
export const selectIsLoading = (state: any) => state.livechat.isLoading;
export const selectDataConversation = (state: any) => state.livechat.dataConversation;

// Reducer
const livechatReducer = livechatSlice.reducer;
export default livechatReducer;
