import { ACCESS_TOKEN } from 'constants/authentication';
import { Redirect, Route, RouteProps } from 'react-router-dom';
import DashboardLayout from 'layouts/Dashboard';

const PrivateRoute = (props: RouteProps) => {
  const isSigndIn = Boolean(localStorage.getItem(ACCESS_TOKEN));
  if (!isSigndIn) return <Redirect to="/signin" />;
  return (
    <DashboardLayout>
      <Route {...props} />
    </DashboardLayout>
  );
}

export default PrivateRoute;
