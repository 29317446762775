import { call, put, takeEvery } from '@redux-saga/core/effects';
import authApi from 'api/authApi';
import { ACCESS_TOKEN, CURRENT_USER, REFRESH_TOKEN, FIRST_SESSION } from 'constants/authentication';
import { push } from 'connected-react-router';
import { authActions } from '../slices/authSlice';
export interface ProcessResponseType {
  code: any;
  data: any;
  message: string,
  success: Boolean,
  status: any
}

function* handleRegister(action: any) {
  const payload = action.payload;
  try {
    const response: ProcessResponseType = yield call(authApi.signUp, payload);
    const { message, success } = response?.data;
    if (success) yield put(authActions.registerRequestFinish('Đã gửi link kích hoạt vào email. Bạn vui lòng kiểm tra email'))
    else yield put(authActions.registerRequestFinish(message))
  } catch (error: any) {
    console.log(`error`, error?.response);
    yield put(authActions.registerRequestFinish(""))
  }
}

function* handleLogin(action: any) {
  const payload = action.payload;
  try {
    const response: ProcessResponseType = yield call(authApi.login, payload);
    const { data, message, success } = response?.data;
    if (success) {
      localStorage.setItem(ACCESS_TOKEN, data?.token);
      localStorage.setItem(REFRESH_TOKEN, data?.refreshToken);
      localStorage.setItem(CURRENT_USER, JSON.stringify(data?.user || {}));
      localStorage.setItem(FIRST_SESSION, JSON.stringify(0));
      yield put(authActions.loginSuccess(data?.user));
      yield put(push('/'));
    } else {
      yield put(authActions.loginFailed(message));
    }
  } catch (error: any) {
    yield put(authActions.loginFailed(error?.response?.data?.message || ''));
  }
}

function* handleLogout() {
  localStorage.removeItem(ACCESS_TOKEN);
  localStorage.removeItem(REFRESH_TOKEN)
  localStorage.removeItem(FIRST_SESSION)
  yield put(push('/signin'));
}

const authSaga = [
  takeEvery(authActions.registerRequest.type, handleRegister),
  takeEvery(authActions.login.type, handleLogin),
  takeEvery(authActions.logout.type, handleLogout),
];

export default authSaga
