import axios from 'axios';
import { configEnv } from 'configs';
import { REFRESH_TOKEN_TIKTOK } from 'constants/authentication';
import { CommonResponse } from '../models';
import axiosClient from './axiosClientTiktok';

const tiktokApi = {
  getInfoToken(code: string): Promise<CommonResponse> {
    const url = configEnv.REACT_APP_BASE_API_URL + '/redirect';
    const path =
      configEnv.REACT_APP_TIKTOK_BASE_URL +
      `/oauth/access_token/?client_key=${configEnv.REACT_APP_TIKTOK_CLIENT_KEY}&client_secret=${configEnv.REACT_APP_TIKTOK_CLIENT_SECRET}&grant_type=authorization_code&code=${code}`;
    return axiosClient.post(url, { path, method: 'POST' });
  },
  refreshToken(): Promise<CommonResponse> {
    const url = configEnv.REACT_APP_BASE_API_URL + '/redirect';
    const refresh_token = window.localStorage.getItem(REFRESH_TOKEN_TIKTOK);
    const path =
      configEnv.REACT_APP_TIKTOK_BASE_URL +
      `/oauth/refresh_token/?client_key=${configEnv.REACT_APP_TIKTOK_CLIENT_KEY}&grant_type=refresh_token&refresh_token=${refresh_token}`;
    return axiosClient.post(url, { path, method: 'POST' });
  },
  getUserInfo(params: {
    open_id: string;
    access_token: string;
    fields: Array<string>;
  }): Promise<CommonResponse> {
    const url = configEnv.REACT_APP_TIKTOK_BASE_URL + `/user/info/`;
    return axiosClient.post(url, params);
  },
  getMyVideoList(params: {
    open_id: string;
    access_token: string;
    cursor: any;
    max_count: number;
    fields: Array<string>;
  }): Promise<CommonResponse> {
    const url = configEnv.REACT_APP_TIKTOK_BASE_URL + `/video/list/`;
    // return axiosClient.get(`tiktok/get_videos`);
    return axiosClient.post(url, params);
  },
  getVideoByID(params: {
    access_token: string;
    filters: Object;
    fields: Array<string>;
  }): Promise<CommonResponse> {
    const url = configEnv.REACT_APP_TIKTOK_BASE_URL + `/video/query/`;
    return axiosClient.post(url, params);
  },
  getCommentByVideoID(
    aweme_id: string,
    cursor?: number,
    cookie?: string
  ): Promise<CommonResponse> {
    const url = configEnv.REACT_APP_BASE_API_URL + '/redirect';
    const path = `https://api22-normal-c-useast2a.tiktokv.com/aweme/v2/comment/list/?aweme_id=${aweme_id}&count=20&cursor=${cursor}`;
    return axios.post(url, {
      path,
      method: 'GET',
      headers: {
        'user-agent': 'not null',
        referer: 'https://www.tiktok.com/',
        cookie: cookie,
      },
    });
  },
  getCommentReply(
    _id: string,
    count?: number,
    cursor?: number,
    cookie?: string
  ): Promise<CommonResponse> {
    const url = configEnv.REACT_APP_BASE_API_URL + '/redirect';
    const path = `https://www.tiktok.com/api/comment/list/reply/?comment_id=${_id}&count=${count || 10
      }&cursor=${cursor || 0}`;
    return axios.post(url, {
      path,
      method: 'GET',
      headers: {
        'user-agent': 'not null',
        referer: 'https://www.tiktok.com/',
        cookie: cookie,
      },
    });
  },
  deleteCommentTiktok(
    cid: string,
    cookie?: string,
    csrfToken?: string,
  ): Promise<CommonResponse> {
    const url = configEnv.REACT_APP_BASE_API_URL + '/redirect';
    const path = `https://api22-normal-c-useast2a.tiktokv.com/aweme/v1/comment/delete/?cid=${cid}`;
    return axios.post(url, {
      path,
      method: 'POST',
      headers: {
        'user-agent': 'not null',
        referer: 'https://www.tiktok.com/',
        'x-secsdk-csrf-token': csrfToken,
        cookie: cookie,
      },
    });
  },
  likeCommentTitkok(
    aweme_id: string,
    cid: string,
    cookie?: string,
    csrfToken?: string,
  ): Promise<CommonResponse> {
    const url = configEnv.REACT_APP_BASE_API_URL + '/redirect';
    const path = `https://api22-normal-c-useast2a.tiktokv.com/aweme/v1/comment/digg/?aid=1180&aweme_id=${aweme_id}&cid=${cid}&digg_type=1`;
    return axios.post(url, {
      path,
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
        "Cookie": cookie,
        'User-Agent': "python-requests/2.28.1",
      },
    });
  },
  deleteLikeCommentTiktok(
    aweme_id: string,
    cid: string,
    cookie?: string,
    csrfToken?: string,
  ): Promise<CommonResponse> {
    const url = configEnv.REACT_APP_BASE_API_URL + '/redirect';
    const path = `https://api22-normal-c-useast2a.tiktokv.com/aweme/v1/comment/digg/?aid=1180&aweme_id=${aweme_id}&cid=${cid}&digg_type=2`;
    return axios.post(url, {
      path,
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
        "Cookie": cookie,
        'User-Agent': "python-requests/2.28.1",
      },
    });
  },
  updateCookieToSystem(
    projectId: string,
    cookie: string
  ): Promise<CommonResponse> {
    const url = configEnv.REACT_APP_BASE_API_URL + '/projects/update_cookie';
    return axios.post(url, {
      cookie,
      id: projectId,
    });
  },
  getCsrfToken(cookie: string, params: string): Promise<CommonResponse> {
    const url = configEnv.REACT_APP_BASE_API_URL + '/redirect';
    const path = `https://www.tiktok.com/api/comment/${params}/`;
    return axios.post(url, {
      path,
      method: 'HEAD',
      headers: {
        referer: 'https://www.tiktok.com/',
        cookie: cookie,
        'x-secsdk-csrf-request': '1',
        'x-secsdk-csrf-version': '1.2.7',
      },
    });
  },
  postComment(
    aweme_id: string,
    text: string,
    reply_id: string,
    cookieString: string,
    csrfToken: string
  ): Promise<CommonResponse> {
    const url = configEnv.REACT_APP_BASE_API_URL + '/redirect';
    const path = `https://api22-normal-c-useast2a.tiktokv.com:443/aweme/v1/comment/publish/?aid=1180&iid=${aweme_id}`;
    return axios.post(url, {
      path,
      method: 'POST',
      type: "v22",
      body: `aweme_id=${aweme_id}&text=${encodeURIComponent(text)}&text_extra=&is_self_see=0&channel_id=3&action_type=0&publish_scenario=0&skip_rethink=0&reply_id=${reply_id}`,
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
        "Cookie": cookieString,
        'User-Agent': "python-requests/2.28.1",
      },
    });
  },
  getVideosByTitle(title: string): Promise<CommonResponse> {
    const url = `/tiktok/get_videos_by_title?title=${title}`;
    return axiosClient.get(url);
  },
  getCustomersByName(name: string, videoID: string): Promise<CommonResponse> {
    const url = `/tiktok/get_customers_by_name?name=${name}&videoID=${videoID}`;
    return axiosClient.get(url);
  },
  getCommentsNonReply(videoID: string): Promise<CommonResponse> {
    const url = `/tiktok/get_comments_non_reply?videoID=${videoID}`;
    return axiosClient.get(url);
  },
  getCommentsByKeyword(
    keyword: string,
    videoID: string
  ): Promise<CommonResponse> {
    const url = `/tiktok/get_comments_by_keyword?keyword=${keyword}&videoID=${videoID}`;
    return axiosClient.get(url);
  },
  getNewestComment(videoID: string): Promise<CommonResponse> {
    const url = `/tiktok/get_newest_comments?videoID=${videoID}`;
    return axiosClient.get(url);
  },
  getMostReactionComments(videoID: string): Promise<CommonResponse> {
    const url = `/tiktok/get_most_reaction_comments?videoID=${videoID}`;
    return axiosClient.get(url);
  },
  updateIsSeenComment(params: Object): Promise<CommonResponse> {
    const url = '/tiktok/update_is_seen_comment';
    return axiosClient.put(url, params);
  },
  updateReplyCommentTotal(params: Object): Promise<CommonResponse> {
    const url = '/tiktok/update/reply_comment_total';
    return axiosClient.put(url, params);
  },
  updateLikeComment(params: Object): Promise<CommonResponse> {
    const url = '/tiktok/update/like_comment';
    return axiosClient.put(url, params);
  },
  saveCommentSystem(params: Object): Promise<CommonResponse> {
    const url = '/tiktok/save/comment_system';
    return axiosClient.post(url, params);
  },
  statisticCommentSystemByDay(): Promise<CommonResponse> {
    const url = '/tiktok/statistic/comment_system_by_day';
    return axiosClient.get(url);
  },
  filterComment(
    videoID: string,
    keyword: string,
    name: string,
    newest: boolean,
    non_reply: boolean,
  ): Promise<CommonResponse> {
    const url = `/tiktok/filter_comment?videoID=${videoID}&keyword=${keyword || ""}
      &name=${name || ""}&newest=${newest || false}&non_reply=${non_reply || false}`;
    return axiosClient.get(url);
  },
  getNewestCommentOfSystem(page: number): Promise<CommonResponse> {
    const url = `/tiktok/get_newest_comment_of_system?page=${page}`;
    return axiosClient.get(url);
  },
  pinVideo(params: Object): Promise<CommonResponse> {
    const url = `/tiktok/add_mark_video`
    return axiosClient.post(url, params);
  },
  getVideoPined(): Promise<CommonResponse>  {
    const url = 'tiktok/list_mark_video'
    return axiosClient.get(url);
  },
  unVideoPined(id: string): Promise<CommonResponse>  {
    const url = `tiktok/delete_mark_video/${id}`
    return axiosClient.delete(url);
  }
};
export default tiktokApi;
