export const configEnv = {
  REACT_APP_BASE_API_URL: "https://api.va-crm.ftech.ai/v1/api",
  REACT_APP_DOMAIN_SERVER: "https://api.va-crm.ftech.ai",
  REACT_APP_DOMAIN_CLIENT: "=https://va-crm.ftech.ai",
  REACT_APP_YOUTUBE_API_KEY: "AIzaSyAOmjPD8FngKNfTMS1meMqh30h7JsmyFZY",
  REACT_APP_YOUTUBE_CLIENT_ID: "946848852646-5l0r4b5q2dnaheihkq7lf2ap7sh41e9g.apps.googleusercontent.com",
  REACT_APP_INSTAGRAM_API: "https://graph.facebook.com/v13.0",
  REACT_APP_INSTAGRAM_APP_ID: "8480031832022388",
  REACT_APP_TIKTOK_CLIENT_KEY: "awua3oxdj1k9z8ym",
  REACT_APP_TIKTOK_CLIENT_SECRET: "280ac977cc999b71f620fdac31859c27",
  REACT_APP_TIKTOK_BASE_URL: "https://open-api.tiktok.com",
  REACT_APP_TIKTOK_REDIRECT_URI: "https://va-crm.ftech.ai/oauth-callback/"
}
