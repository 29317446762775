import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface ProjectState {
  isLoading?: boolean;
  listUsersOfProject?: any;
  listAllUsers?: any;
  selectedUser?: any
}

const initialState: ProjectState = {
  isLoading: false,
  listUsersOfProject: undefined,
  listAllUsers: undefined,
  selectedUser: undefined
}

const projectSlice = createSlice({
  name: "project",
  initialState,
  reducers: {
    getListUsersOfProject(state, action: PayloadAction<any>) {
      state.listUsersOfProject = action.payload
    },
    getListAllUsers(state, action: PayloadAction<any>) {
      state.listAllUsers = action.payload
    },
    getSelectedUser(state, action: PayloadAction<any>) {
      state.selectedUser = action.payload
    }
  }
})

//Actions
export const projectActions = projectSlice.actions;

// Selectors
export const selectListUsersOfProject = (state: any) => state.project.listUsersOfProject;
export const selectListAllUsers = (state: any) => state.project.listAllUsers;
export const selectedUser = (state: any) => state.project.selectedUser;

// Reducer
const projectReducer = projectSlice.reducer;
export default projectReducer;
