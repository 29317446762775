import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import App from './containers/App';
import configureStore from './redux/stores/store';
import { Provider } from 'react-redux';
import * as serviceWorker from './serviceWorker';
import { Spin } from 'antd';
import './index.css';
import '../node_modules/font-awesome/css/font-awesome.min.css';
import TiktokContextProvider from 'contexts/tiktokContext';
import * as Sentry from "@sentry/react";
// import { BrowserTracing } from "@sentry/tracing";

const store = configureStore();
const rootElement = document.getElementById('root');

if (process.env.REACT_APP_SENTRY_DNS) {
  Sentry.init({
    dsn: "https://69b8aeb69b3e48c0b448ca4941f1ec53@sentry.tool.ftech.ai/70",

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  });
}

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <Suspense fallback={<div className="spinner"><Spin size="large" tip="Loading..." /></div>}>
        <TiktokContextProvider>
          <App />
        </TiktokContextProvider>
      </Suspense>
    </Provider>
  </React.StrictMode>,
  rootElement
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
