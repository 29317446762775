import React, { useState, useEffect, useContext } from 'react';
import { Layout, Menu, Row, Avatar, Dropdown, Popover } from 'antd';
import {
  UserOutlined,
  ProjectOutlined,
  ArrowLeftOutlined,
  YoutubeFilled,
  InstagramOutlined,
  WarningOutlined
} from '@ant-design/icons';
import TikTokIcon from 'assets/icons/icon-tiktok.svg';

import './index.css';
import { Link, useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { authActions } from 'redux/slices/authSlice';
import useQuery from 'hooks/useQuery';
import Logo from './logo.png';
import LogoWap from './logo-wap.png';
import { TiktokContext } from 'contexts/tiktokContext';

const { Header, Sider, Content } = Layout;
const { SubMenu } = Menu;
interface LayoutProps {
  children: React.ReactNode;
}

const DashboardLayout = (props: LayoutProps) => {
  const { count } = useContext(TiktokContext);
  const query = useQuery();
  const history = useHistory();
  const pathName = history?.location?.pathname || '';
  const [collapsed, setCollapsed] = useState(false);
  const dispatch = useDispatch();
  const [hasSubmenuProject, setHasSubmenuProject] = useState(false);
  const [defaultSelectedKeys, setDefaultSelectedKeys] = useState(query?.tab || 'channel');
  const projectId: string = pathName.split('/')[2];
  const isTabTiktok = pathName.includes('tiktok') || query?.tab === 'tiktok';

  const content = (
      <p style={{fontSize: '14px'}}>Không nên nhắn quá 297 tin nhắn 1 ngày</p>
  );

  const toggle = () => {
    setCollapsed(!collapsed);
  };

  const handleLogout = () => {
    dispatch(authActions.logout());
  };

  const customGoBackButton = () => {
    const arrayPath = history.location.pathname.split('/');
    let tabName: string = '';
    const isTiktokTab: Boolean = arrayPath.includes('tiktok');
    const isChannelTab: Boolean = arrayPath.includes('livechat');
    if(isTiktokTab) {
      tabName = 'tiktok';
    }
    if(isChannelTab) {
      tabName = 'livechat';
    }
    switch(tabName) {
      case 'tiktok':
        history.push(`/project/${projectId}/?tab=tiktok`);
        break;
      case 'livechat':
        history.push(`/project/${projectId}/?tab=channel`);
        break;
      default:
        history.goBack();
        break;
    }
  };

  useEffect(() => {
    if (Object.keys(query).length > 0 && pathName.includes('/project')) {
      setHasSubmenuProject(true);
      setDefaultSelectedKeys(query?.tab);
    } else setHasSubmenuProject(false);
  }, [query, pathName]);

	useEffect(() => {
		if(query?.success === 'true') {
      history.push(`/project/${projectId}?tab=channel`);
		}
	},[query])

  return (
    <Layout className="dashboard-layout">
      <Sider
        collapsible
        collapsed={collapsed}
        onCollapse={toggle}
        width="210px"
      >
        <div className="logo" onClick={() => history.push('/')}>
          <img src={!collapsed ? Logo : LogoWap} alt="" />
        </div>
        <Menu
          theme="dark"
          mode="inline"
          defaultSelectedKeys={[defaultSelectedKeys]}
          defaultOpenKeys={['project']}
          selectedKeys={[query?.tab]}
        >
          {hasSubmenuProject ? (
            <SubMenu key="project" icon={<ProjectOutlined />} title="Project">
              <Menu.Item
                key="channel"
                onClick={() => history.push(`/project/${projectId}?tab=channel`)}
              >
                Quản lý Channel
              </Menu.Item>
              <Menu.Item
                key="user"
                onClick={() => history.push(`/project/${projectId}?tab=user`)}
              >
                Quản lý thành viên
              </Menu.Item>
              <Menu.Item
                key="live-chat"
                onClick={() => history.push(`/project/${projectId}?tab=live-chat`)}
              >
                Quản lý live chat
              </Menu.Item>
              <Menu.Item
                key="instagram"
                onClick={() => history.push(`/project/${projectId}?tab=instagram`)}
                icon={<InstagramOutlined />}
              >
                Tích hợp Instagram
              </Menu.Item>
              <Menu.Item
                key="tiktok"
                onClick={() => history.push(`/project/${projectId}?tab=tiktok`)}
                icon={
                  <img
                    src={TikTokIcon}
                    alt="tiktok"
                    style={{
                      width: '10px',
                      height: '30px',
                      objectFit: 'cover',
                    }}
                  />
                }
              >
                Tích hợp TikTok
              </Menu.Item>
              <Menu.Item
                key="youtube"
                onClick={() => history.push(`/project/${projectId}?tab=youtube`)}
                icon={<YoutubeFilled />}
              >
                Tích hợp Youtube
              </Menu.Item>
              <Menu.Item
                key="zalo"
                onClick={() => history.push(`/project/${projectId}?tab=zalo`)}
                icon={<YoutubeFilled />}
              >
                Tích hợp Zalo
              </Menu.Item>
            </SubMenu>
          ) : (
            <Menu.Item key="project" icon={<ProjectOutlined />}>
              <Link to="/">Project</Link>
            </Menu.Item>
          )}
        </Menu>
      </Sider>
      <Layout className="site-layout">
        <Header className="site-layout-background" style={{ padding: 0 }}>
          <Row justify="space-between" style={{ alignItems: 'center' }}>
            <div>
              {pathName !== '/' && (
                <ArrowLeftOutlined
                  className="trigger"
                  onClick={() => customGoBackButton()}
                />
              )}
            </div>
            <Row>
              {isTabTiktok && <> <h3 style={{ marginRight: "15px" }}>Tổng số bình luận/ngày: <span className={count >= 297 ? "red" : ""}>{count}</span> / 297</h3>
                <Popover content={content} placement="bottomLeft">
                  <WarningOutlined style={{ color: "red", lineHeight: "65px", cursor: "pointer" }} />
                </Popover>
              </>
              }
              <Dropdown
                overlay={
                  <Menu>
                    <Menu.Item key="profile">
                      <Link to="/profile">Tài khoản</Link>
                    </Menu.Item>
                    <Menu.Item key="signout" onClick={handleLogout}>
                      Đăng xuất
                    </Menu.Item>
                  </Menu>
                }
                placement="bottomRight"
              >
                <Avatar icon={<UserOutlined />} className="avatar" />
              </Dropdown>
            </Row>

          </Row>
        </Header>
        <Content
          style={{
            margin: '16px 16px',
          }}
        >
          <div
            className="site-layout-background"
            style={{
              maxWidth: '1600px',
              margin: '0 auto',
              padding: '20px 20px',
              height: '100%'
            }}
          >
            {props.children}
          </div>
        </Content>
      </Layout>
    </Layout>
  );
};

export default DashboardLayout;
