import axiosClient from './axiosClient';
import { CommonResponse } from '../models';

const channelApi = {
  deleteChannel(projectId: string, params: any): Promise<CommonResponse> {
    const url = `projects/remove_channels/${projectId}`;
    return axiosClient.post(url, params);
  },
  getDetailChannels(channelId: string): Promise<CommonResponse> {
    const url = `/channels/get_channel_detail?channelId=${channelId}`;
    return axiosClient.get(url);
  },
  getListConversationByChanel(channelId: string, params: any): Promise<CommonResponse> {
    const paramsString = new URLSearchParams(params).toString();
    const url = `/conversations/get_conversation_by_channel?channelId=${channelId}&${paramsString}`;
    return axiosClient.get(url)
  },
  getConversation(conversationsId: string, params: any): Promise<CommonResponse> {
    const paramsString = new URLSearchParams(params).toString();
    const url = `/message/get_message_by_conversation?conversationId=${conversationsId}&${paramsString}`;
    return axiosClient.get(url)
  },
  addVAToChannel(params: any): Promise<CommonResponse> {
    const url = `va_config/create/`;
    return axiosClient.post(url, params);
  },
  updateAutoReply(id: string, check: boolean): Promise<CommonResponse> {
    const url = `va_config/update-auto-repLy/${id}`;
    return axiosClient.post(url, { isAutoReply: check });
  },
  getVaConfigLiveChat(idChannel: string): Promise<CommonResponse> {
    const url = `va_config/get_conf/${idChannel}`;
    return axiosClient.get(url);
  },
  updateVaConfigLivechat(params: object): Promise<CommonResponse> {
    const url = 'va_config/update';
    return axiosClient.put(url, params);
  },
  getConversationDetail(conversationId: string): Promise<CommonResponse> {
    const url = `/conversations/detail/${conversationId}`;
    return axiosClient.get(url)
  },
  toggleBotInConversation(params: object): Promise<CommonResponse> {
    const url = '/conversations/toggle_bot_in_conversation';
    return axiosClient.put(url, params)
  },
  configLiveChatChannel(options: any): Promise<CommonResponse> {
    const url = `/channels/config_live_chat`;
    return axiosClient.post(url, options)
  },
  getMessage(params: any): Promise<CommonResponse> {
    // const url = `projects/list`;
    // return axiosClient.get(url)
    const url = `message/search-messages`;
    return axiosClient.post(url, params)
  },
  statisticVaLivechat(start_time: any, end_time: any): Promise<CommonResponse> {
    const url = `/channels/statistic_va_livechat?startTime=${start_time}&endTime=${end_time}`;
    return axiosClient.get(url);
  },
	statisticSessionchat(start_time: any, end_time: any, projectId: any): Promise<CommonResponse> {
    const url = `/conversations/statistic_session_chat?startTime=${start_time}&endTime=${end_time}&projectId=${projectId}`;
    return axiosClient.get(url);
  }
}

export default channelApi;


