import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";
import { history } from "utils";
import authReducer from "redux/slices/authSlice";
import projectReducer from "redux/slices/projectSlice";
import livechatReducer from "redux/slices/livechatSlice";
import vaLivechatReducer from "redux/slices/vaLivechatSlice";

const rootReducer = combineReducers({
  router: connectRouter(history),
  auth: authReducer,
  livechat: livechatReducer,
  project: projectReducer,
  vaLivechat: vaLivechatReducer,
});

export type AppState = ReturnType<typeof rootReducer>;

export default rootReducer;
