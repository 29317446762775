import tiktokApi from 'api/tiktokApi';
import React, { createContext, useEffect, useState } from 'react';
import { ACCESS_TOKEN_TIKTOK } from 'constants/authentication';

interface TiktokContextData {
  count: number;
  increaseCount: () => void;
}

export const TiktokContext = createContext<TiktokContextData>({
  count: 0,
  increaseCount() {}
})

interface TiktokContextProps {
  children: React.ReactNode
}

const TiktokContextProvider: React.FC<TiktokContextProps> = ({ children }) => {
  const [count, setCount] = useState<number>(0);
  const access_token_tiktok: any = localStorage.getItem(ACCESS_TOKEN_TIKTOK);

  const getCountCommentInOneDay = () => {
    tiktokApi.statisticCommentSystemByDay()
      .then((res: any) => {
        const { success, data } = res.data;
        if(success) {
          setCount(data.count);
        }
      })
      .catch()
  }

  useEffect(() => {
    if(access_token_tiktok) {
      getCountCommentInOneDay();
    }
  }, [access_token_tiktok])

  const increaseCount = () => {
    setCount(count => count + 1);
  }

  const tiktokContextData = {
    count,
    increaseCount
  }

  return (
    <TiktokContext.Provider value={tiktokContextData}>
      {children}
    </TiktokContext.Provider>
  )
}

export default TiktokContextProvider